@import './src/scss/variables';
@import './src/scss/app';
@import './games/colorpicker/src/scss/game';

.widget {
	&__scheme {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;

    &-item {
	    border: 1px solid;
	    padding: .5rem;
	    display: flex;
	    flex-direction: column;
	    gap: .5rem;

	    .color-swatch {
		    height: 6rem;
		    opacity: 1 !important;
			}
    }

    &-list {
	    display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    gap: .5rem;

	    li {
        display: flex;
        gap: 5.25px;
	    }

	    a {
	      @include text-decoration;
	    }
    }
	}
}